import React, { useContext, useEffect, useState } from 'react'
import {
  ButtonRow,
  DividerWrapper,
  DividerWrapper2,
  EditWrapper,
  FlexItem,
  HeaderRow,
  IconWrapper,
  Individual,
  LabelWrap,
  LoaderRow,
  MasterWrapper,
  PrimaryButtonWrapper,
  Row,
  SecondaryButtonWrapper,
  TertiaryButtonWrapper,
  TitleIconWrap,
  TopRow,
} from './styles'
import Divider from '../../components/divider/Divider'
import {
  WDReadModeTitle,
  WDReadModeValue,
  WDStyledCancelButtonLabel,
  WDStyledEditButtonLabel,
  WDStyledSectionTitle,
} from '../../components/ui/WDTypography'
import { SvgChevronUp } from '../../components/svg/SvgChevronUp'
import { SvgChevronDown } from '../../components/svg/SvgChevronDown'
import { SvgAccountDetails } from '../../components/svg/SvgAccountDetails'
import { InputField } from '../inputField/InputField'
import { COLORS } from '../../theme/Colors'
import {
  WDStyledCancelButton,
  WDStyledEditButton,
  WDStyledSaveButton,
} from '../../components/ui/WDButtons'
import { AccountDetailsContext } from '../../services/accountDetailsContext/AccountDetailsContext'
import {
  AccountDetailsFieldValues,
  AccountDetailsUpdatedValues,
  ContactInfo,
  ContactList,
  ContactType,
  EmailInfo,
  EmailType,
} from '../../services/accountDetailsContext/AccountDetailsInterface'
import { ErrorModal } from '../errorModal/ErrorModal'
import Loader from '../../components/loader/Loader'
import AccountDetailsSubmissionPage from './AccountDetailsSubmissionPage'
import { AuthContext } from '../../services/AuthContext'
import { SvgExclamation } from '../../components/svg/SvgExclamation'
import { InlineErrorWrapper } from '../mailingAddress/styles'
import Label from '../../components/label/Label'
import { ApprovalContext } from '../../services/approvalContext/ApprovalContext'
import { AddWrapper, ContentWrapper } from '../auditTrailUI/styles'
import Image from '../../components/image/Image'
import Button from '../../components/button/Button'
import { Icons } from '../../shared/GlobalStyle'
import {
  checkIsP3Account,
  countPhoneList,
  findContact,
  formatPhoneNumber,
  validateContact,
} from '../../shared/helper'
import { messages } from '../../shared/constants'
import ConfirmationDailog from '../confirmationDialog/ConfirmationDialog'
import { AuditTrailContext } from '../../services/auditTrailContext/AuditTrailContext'

export const AccountDetailsUI = () => {
  const accountDetailsValues = useContext(AccountDetailsContext)
  const auditTrailValues = useContext(AuditTrailContext)
  const authCtx = useContext(AuthContext)
  const accountDetails = accountDetailsValues?.accountDetails
  const [phoneList, setPhoneList] = useState<ContactList[]>()
  const [businessPhone, setBusinessPhone] = useState<ContactList>()
  const [fax, setFax] = useState<ContactList>()
  const [homePhone, setHomePhone] = useState<ContactList>()
  const [mobilePhone, setMobilePhone] = useState<ContactList>()
  const [alternate, setAlternate] = useState<EmailInfo>()
  const [personal, setPersonal] = useState<EmailInfo>()
  const [alternateEmailType, setAlternameEmailType] = useState<EmailType>()
  const [personalEmailType, setPersonalEmailType] = useState<EmailType>()
  const [homePhoneNumber, setHomePhoneNumber] = useState<string>()
  const [businessPhoneNumber, setBusinessPhoneNumber] = useState<string>()
  const [mobilePhoneNumber, setMobilePhoneNumber] = useState<string>()
  const [faxNumber, setFaxNumber] = useState<string>()
  const [removedContact, setRemovedContact] = useState<ContactList>()
  const [removedEmail, setRemovedEmail] = useState<EmailInfo>()
  const [removeItem, setRemoveItem] = useState<string>('')
  const success = accountDetailsValues?.errorMsg
  const removePhoneResponseCode = accountDetailsValues?.removePhoneResponseCode
  const removeEmailResponseCode = accountDetailsValues?.removeEmailResponseCode
  const approval = useContext(ApprovalContext)


  useEffect(() => {
    accountDetails
  }, [accountDetails])

  const contactList = accountDetails?.contactType?.map((phone: ContactType) => {
    const contact = accountDetails?.contactInfo?.find(
      (contact) => contact.contactTypeId === phone.contactTypeId
    )
    return {
      contactType: phone.contactType as string,
      contact: contact ?? {
        contactId: 0,
        contactTypeId: phone.contactTypeId as number,
        phoneNumber: '',
        description: '',
      },
      isError: false,
      errorMessage: messages.account_details_ui.phone_error.no_error,
      contactNumberFormatted:
        formatPhoneNumber(contact?.phoneNumber as string) ?? '',
    }
  })

  useEffect(() => {
    setPhoneList(contactList)
    if (accountDetails?.emailType !== undefined) {
      const tempEOne = accountDetails?.emailType?.filter(
        (emailType) =>
          emailType.emailType ===
          messages.account_details_ui.field_names.alternate
      )
      setAlternameEmailType({
        emailTypeId: tempEOne[0]?.emailTypeId,
        emailType: tempEOne[0]?.emailType,
      })
    }

    if (accountDetails?.emailType !== undefined) {
      const tempETwo = accountDetails?.emailType?.filter(
        (emailType) =>
          emailType.emailType ===
          messages.account_details_ui.field_names.personal
      )
      setPersonalEmailType({
        emailTypeId: tempETwo[0]?.emailTypeId,
        emailType: tempETwo[0]?.emailType,
      })
    }

    if (accountDetails?.emailInfo !== undefined) {
      const tempTwo = accountDetails?.emailInfo?.filter(
        (emailType) =>
          emailType.emailType ===
          messages.account_details_ui.field_names.personal
      )

      setPersonal({
        emailTypeId: tempTwo[0]?.emailTypeId ?? 0,
        emailAddress: tempTwo[0]?.emailAddress ?? '',
        emailType:
          tempTwo[0]?.emailType ??
          messages.account_details_ui.field_names.personal,
        emailId: tempTwo[0]?.emailId ? tempTwo[0]?.emailId : 0,
      })
      const tempOne = accountDetails?.emailInfo?.filter(
        (emailType) =>
          emailType.emailType ===
          messages.account_details_ui.field_names.alternate
      )
      setAlternate({
        emailTypeId: tempOne[0]?.emailTypeId ?? 0,
        emailAddress: tempOne[0]?.emailAddress ?? '',
        emailType:
          tempOne[0]?.emailType ??
          messages.account_details_ui.field_names.alternate,
        emailId: tempOne[0]?.emailId ? tempOne[0]?.emailId : 0,
      })
    }
  }, [accountDetails?.contactInfo, accountDetails?.emailInfo])

  useEffect(() => {
    setFax(
      findContact(
        phoneList as ContactList[],
        messages.account_details_ui.field_names.fax_phone
      )
    )
    setBusinessPhone(
      findContact(
        phoneList as ContactList[],
        messages.account_details_ui.field_names.business_phone
      )
    )
    setHomePhone(
      findContact(
        phoneList as ContactList[],
        messages.account_details_ui.field_names.home_phone
      )
    )
    setMobilePhone(
      findContact(
        phoneList as ContactList[],
        messages.account_details_ui.field_names.mobile_phone
      )
    )
  }, [phoneList])
  const [accountDetailsTestValues, setAccountDetailsTestValues] = useState({
    accountType: `${accountDetails?.accountType}`,
    fullTitleOfAccount: `${accountDetails?.fullTitleOfAccount}`,
    accountNickname: `${accountDetails?.accountNickName}`,
    advisorName: `${accountDetails?.advisorName}`,
    repCode: `${accountDetails?.repCode}`,
    // accountClassId: `${accountDetails?.accountClassId}`,
    status: accountDetails?.status ?? '',
    accountOpeningDate: accountDetails?.accountOpeningDate?.slice(0, 10),
    homePhoneType: 'HomePhone',
    homePhoneNumber: homePhone?.contact?.phoneNumber ?? '',
    mobilePhoneType: 'MobilePhone',
    mobilePhoneNumber: mobilePhone?.contact?.phoneNumber ?? '',
    businessPhoneType: 'BusinessPhone',
    businessPhoneNumber: businessPhone?.contact?.phoneNumber ?? '',
    fax: 'Fax',
    faxNumber: fax?.contact?.phoneNumber ?? '',
    emailAddress: personal?.emailAddress ? personal?.emailAddress : '',
    emailAddress2: alternate?.emailAddress ? alternate?.emailAddress : '',
  })

  useEffect(() => {
    setAccountDetailsTestValues({
      accountType: `${accountDetails?.accountType}`,
      fullTitleOfAccount: `${accountDetails?.fullTitleOfAccount}`,
      accountNickname: `${accountDetails?.accountNickName}`,
      advisorName: `${accountDetails?.advisorName}`,
      repCode: `${accountDetails?.repCode}`,
      // accountClassId: `${accountDetails?.accountClassId}`,
      status: accountDetails?.status ?? '',
      accountOpeningDate: accountDetails?.accountOpeningDate?.slice(0, 10),
      homePhoneType: 'HomePhone',
      homePhoneNumber: homePhone?.contact?.phoneNumber as string,
      mobilePhoneType: 'MobilePhone',
      mobilePhoneNumber: mobilePhone?.contact?.phoneNumber as string,
      businessPhoneType: 'BusinessPhone',
      businessPhoneNumber: businessPhone?.contact?.phoneNumber as string,
      fax: 'Fax',
      faxNumber: fax?.contact?.phoneNumber as string,
      emailAddress: personal?.emailAddress ? personal?.emailAddress : '',
      emailAddress2: alternate?.emailAddress ? alternate?.emailAddress : '',
    })
  }, [
    accountDetails?.accountClassId,
    accountDetails?.status,
    accountDetails?.accountNickName,
    accountDetails?.accountOpeningDate,
    accountDetails?.accountType,
    accountDetails?.advisorName,
    accountDetails?.fullTitleOfAccount,
    accountDetails?.repCode,
    businessPhone,
    fax,
    homePhone,
    mobilePhone,
    personal?.emailAddress,
    alternate?.emailAddress,
  ])

  const [accountDetailsFieldValues, setAccountDetailsFieldValues] = useState({
    fullTitleOfAccount: `${accountDetails?.fullTitleOfAccount}`,
    accountNickname: `${accountDetails?.accountNickName}`,
    advisorName: `${accountDetails?.advisorName}`,
    repCode: `${accountDetails?.repCode}`,
    // accountClassId: `${accountDetails?.accountClassId}`,
    status: accountDetails?.status ?? '',
    accountOpeningDate: accountDetails?.accountOpeningDate?.slice(0, 10),
    homePhoneType: 'HomePhone',
    homePhoneNumber: homePhone?.contact?.phoneNumber ?? '',
    mobilePhoneType: 'MobilePhone',
    mobilePhoneNumber: mobilePhone?.contact?.phoneNumber ?? '',
    businessPhoneType: 'BusinessPhone',
    businessPhoneNumber: businessPhone?.contact?.phoneNumber ?? '',
    fax: 'Fax',
    faxNumber: fax?.contact?.phoneNumber ?? '',
    emailAddress: personal?.emailAddress ? personal?.emailAddress : '',
    emailAddress2: alternate?.emailAddress ? alternate?.emailAddress : '',
  })
  useEffect(() => {
    setAccountDetailsFieldValues({
      fullTitleOfAccount: `${accountDetails?.fullTitleOfAccount}`,
      accountNickname: `${accountDetails?.accountNickName}`,
      advisorName: `${accountDetails?.advisorName}`,
      repCode: `${accountDetails?.repCode}`,
      // accountClassId: `${accountDetails?.accountClassId}`,
      status: accountDetails?.status ?? '',
      accountOpeningDate: accountDetails?.accountOpeningDate?.slice(0, 10),
      homePhoneType: 'HomePhone',
      homePhoneNumber: homePhone?.contact?.phoneNumber as string,
      mobilePhoneType: 'MobilePhone',
      mobilePhoneNumber: mobilePhone?.contact?.phoneNumber as string,
      businessPhoneType: 'BusinessPhone',
      businessPhoneNumber: businessPhone?.contact?.phoneNumber as string,
      fax: 'Fax',
      faxNumber: fax?.contact?.phoneNumber as string,
      emailAddress: personal?.emailAddress ? personal?.emailAddress : '',
      emailAddress2: alternate?.emailAddress ? alternate?.emailAddress : '',
    })
  }, [accountDetails?.accountClassId, accountDetails?.accountNickName, accountDetails?.accountOpeningDate, accountDetails?.accountType, accountDetails?.advisorName, accountDetails?.fullTitleOfAccount, accountDetails?.repCode, businessPhone, fax, homePhone, mobilePhone, personal?.emailAddress, alternate?.emailAddress, accountDetails?.status])

  useEffect(() => {
    return () => {
      setPersonal({ ...personal, isError: false, errorMessage: '' })
      setAlternate({ ...alternate, isError: false, errorMessage: '' })
    }
  }, [])

  const AccountDetails = {
    FullTitleOfAccount: 'Full Title of Account',
    AccountNickname: 'Account Nickname',
    AdvisorName: 'Advisor Name',
    RepCode: 'Rep Code',
    // AccountClassId: 'Account Class ID',
    Status: 'Status',
    AccountOpeningDate: 'Account Opening Date',
    HomePhoneNumber: 'Home Phone Number',
    BusinessPhoneNumber: 'Business Phone Number',
    MobilePhoneNumber: 'Mobile Phone Number',
    FaxNumber: 'Fax Number',
    EmailAddress: 'Email Address',
    EmailAddress2: 'Email Address 2',
  }

  const fieldValues = {
    FullTitleOfAccount: `${accountDetails?.fullTitleOfAccount}`,
    AccountNickname: `${accountDetails?.accountNickName}`,
    AdvisorName: `${accountDetails?.advisorName}`,
    RepCode: `${accountDetails?.repCode}`,
    // AccountClassId: `${accountDetails?.accountClassId}`,
    Status: accountDetails?.status,
    AccountOpeningDate: accountDetails?.accountOpeningDate?.slice(0, 10),
    HomePhoneNumber: `${homePhone?.contact?.phoneNumber}`,
    BusinessPhoneNumber: `${businessPhone?.contact?.phoneNumber}`,
    MobilePhoneNumber: `${mobilePhone?.contact?.phoneNumber}`,
    Fax: `${fax?.contact?.phoneNumber}`,
    EmailAddress: `${personal?.emailAddress ? personal?.emailAddress : ''}`,
    EmailAddress2: `${alternate?.emailAddress ? alternate?.emailAddress : ''}`,
  }

  const accountDetailsFieldTitles = {
    fullTitleOfAccount: 'Full Title of Account',
    accountNickname: 'Account Nickname',
    advisorName: 'Advisor Name',
    repCode: 'Rep Code',
    // accountClassId: 'Account Class ID',
    status: 'Status',
    accountOpeningDate: 'Account Opening Date',
    homePhoneType: 'Home Phone',
    homePhoneNumber: `Home Phone Number`,
    mobilePhoneType: 'MobilePhone',
    mobilePhoneNumber: `Mobile Phone Number`,
    businessPhoneType: 'BusinessPhone',
    businessPhoneNumber: `Business Phone Number`,
    fax: 'Fax',
    faxNumber: `Fax`,
    emailAddress: `Email Address`,
    emailAddress2: `Email Address 2`,
  }

  interface TableData {
    field?: string
    oldValue?: string
    newValue?: string
  }

  const getObjectDifferences = (
    obj1: AccountDetailsFieldValues,
    obj2: AccountDetailsFieldValues,
    fieldObj: AccountDetailsFieldValues
  ) => {
    const differences: TableData[] = []
    for (const key in obj1) {
      if (
        Object.hasOwnProperty.call(obj1, key) &&
        Object.hasOwnProperty.call(obj2, key)
      ) {
        if (
          obj1[key as keyof AccountDetailsFieldValues] !==
          obj2[key as keyof AccountDetailsFieldValues]
        ) {
          differences.push({
            field: fieldObj[key as keyof AccountDetailsFieldValues],
            oldValue: obj1[key as keyof AccountDetailsFieldValues],
            newValue: obj2[key as keyof AccountDetailsFieldValues],
          })
        }
      }
    }
    return differences
  }

  const handleChange = (value: string, type: string) => {
    if (type === 'FullTitleOfAccount') {
      fieldValues.FullTitleOfAccount = value
    } else if (type === 'AccountNickname') {
      fieldValues.AccountNickname = value
      accountDetailsFieldValues.accountNickname = value
    } else if (type === 'AdvisorName') {
      fieldValues.AdvisorName = value
    }
  }

  const handlePhoneNumber = (value: string, type: string) => {
    const phone = value.toString().replace(/[^\d]/g, '')
    {
      if (type === 'HomePhone') {
        if (!accountDetailsValues?.showConfirmDialog)
          validateContact(homePhone as ContactList, phone)
        if (phone.length <= 10)
          accountDetailsFieldValues.homePhoneNumber = phone
        if (homePhone !== undefined) {
          setHomePhoneNumber(formatPhoneNumber(phone as string))
          homePhone.contactNumberFormatted = formatPhoneNumber(phone as string)
          setHomePhone(homePhone)
        }
      } else if (type === 'BusinessPhone') {
        if (!accountDetailsValues?.showConfirmDialog)
          validateContact(businessPhone as ContactList, phone)
        if (phone.length <= 10)
          accountDetailsFieldValues.businessPhoneNumber = phone
        if (businessPhone !== undefined) {
          setBusinessPhoneNumber(formatPhoneNumber(phone as string))
          businessPhone.contactNumberFormatted = formatPhoneNumber(
            phone as string
          )
          setBusinessPhone(businessPhone)
        }
      }
      if (type === 'MobilePhone') {
        if (!accountDetailsValues?.showConfirmDialog)
          validateContact(mobilePhone as ContactList, phone)
        if (phone.length <= 10)
          accountDetailsFieldValues.mobilePhoneNumber = phone
        if (mobilePhone !== undefined) {
          setMobilePhoneNumber(formatPhoneNumber(phone as string))
          mobilePhone.contactNumberFormatted = formatPhoneNumber(
            phone as string
          )
          setMobilePhone(mobilePhone)
        }
      } else if (type === 'Fax') {
        if (!accountDetailsValues?.showConfirmDialog)
          validateContact(fax as ContactList, phone)
        if (phone.length <= 10) accountDetailsFieldValues.faxNumber = phone
        if (fax !== undefined) {
          setFaxNumber(formatPhoneNumber(phone as string))
          fax.contactNumberFormatted = formatPhoneNumber(phone as string)
          setFax(fax)
        }
      }
    }
  }

  const validateEmail = (emailToTest: EmailInfo, emailAddress: string) => {
    if (emailToTest !== undefined && emailAddress !== '') {
      const regex = messages.account_details_ui.email_regex
      const validEmail = regex.test(emailAddress as string)
      if (
        emailToTest.emailType ===
        messages.account_details_ui.field_names.personal
      ) {
        if (validEmail)
          setPersonal({
            ...personal,
            isError: false,
            errorMessage: messages.account_details_ui.email_error.no_error,
          })
        else
          setPersonal({
            ...personal,
            isError: true,
            errorMessage: messages.account_details_ui.email_error.email_invalid,
          })
      } else if (
        emailToTest.emailType ===
        messages.account_details_ui.field_names.alternate
      ) {
        if (validEmail)
          setAlternate({
            ...alternate,
            isError: false,
            errorMessage: messages.account_details_ui.email_error.no_error,
          })
        else
          setAlternate({
            ...alternate,
            isError: true,
            errorMessage: messages.account_details_ui.email_error.email_invalid,
          })
      }
      return validEmail
    } else if (emailAddress === '') {
      if (
        emailToTest.emailType ===
        messages.account_details_ui.field_names.personal
      ) {
        setPersonal({
          ...personal,
          isError: true,
          errorMessage: messages.account_details_ui.email_error.email_empty,
        })
        return false
      } else if (
        emailToTest.emailType ===
        messages.account_details_ui.field_names.alternate
      ) {
        if (fieldValues.EmailAddress2 !== '') {
          setAlternate({
            ...alternate,
            isError: true,
            errorMessage: messages.account_details_ui.email_error.email_empty,
          })
          return false
        } else {
          setAlternate({
            ...alternate,
            isError: false,
            errorMessage: messages.account_details_ui.email_error.no_error,
          })
          return true
        }
      }
    } else {
      return true
    }
  }

  const handleEmailAddress = (value: string, type: string) => {
    if (type === messages.account_details_ui.field_names.personal) {
      fieldValues.EmailAddress = value
      accountDetailsFieldValues.emailAddress = value
      validateEmail(personal as EmailInfo, value as string)
    } else if (type === messages.account_details_ui.field_names.alternate) {
      fieldValues.EmailAddress2 = value
      accountDetailsFieldValues.emailAddress2 = value
      validateEmail(alternate as EmailInfo, value as string)
    }
  }

  const removePhone = (contact: ContactList) => {
    setRemovedContact(contact)
    setRemoveItem(messages.account_details_ui.field_names.phone)
    accountDetailsValues?.setShowConfirmDialog(true)
  }

  const removeEmail = (email: EmailInfo) => {
    setRemovedEmail(email)
    setRemoveItem(messages.account_details_ui.field_names.email)
    accountDetailsValues?.setShowConfirmDialog(true)
  }

  const handleRemove = (value: boolean, isPhone: boolean) => {
    if (isPhone) {
      accountDetailsValues?.setShowConfirmDialog(false)
      if (value) handlePhoneNumber('', removedContact?.contactType as string)
    } else {
      accountDetailsValues?.setShowConfirmDialog(false)
      if (value) fieldValues.EmailAddress2 = ''
    }
  }

  const cancelFunction = () => {
    approval?.setIsActiveAct(!approval?.isActiveAct)
    setPhoneList(contactList)
    setHomePhoneNumber(
      formatPhoneNumber(homePhone?.contact?.phoneNumber as string)
    )
    setBusinessPhoneNumber(
      formatPhoneNumber(businessPhone?.contact?.phoneNumber as string)
    )
    setMobilePhoneNumber(
      formatPhoneNumber(mobilePhone?.contact?.phoneNumber as string)
    )
    setFaxNumber(formatPhoneNumber(fax?.contact?.phoneNumber as string))
    setPersonal({ ...personal, isError: false, errorMessage: '' })
    setAlternate({ ...alternate, isError: false, errorMessage: '' })
  }
  const save = () => {
    const checkHomePhoneLength = validateContact(
      homePhone as ContactList,
      homePhone?.contactNumberFormatted as string
    )
    const checkBusinessPhoneLength = validateContact(
      businessPhone as ContactList,
      businessPhone?.contactNumberFormatted as string
    )
    const checkMobilePhoneLength = validateContact(
      mobilePhone as ContactList,
      mobilePhone?.contactNumberFormatted as string
    )
    const checkFaxLength = validateContact(
      fax as ContactList,
      fax?.contactNumberFormatted as string
    )
    const check = validateEmail(
      personal as EmailInfo,
      accountDetailsFieldValues.emailAddress
    )
    const checkAlt = validateEmail(
      alternate as EmailInfo,
      accountDetailsFieldValues.emailAddress2
    )
    if (
      check &&
      checkAlt &&
      !checkHomePhoneLength &&
      !checkBusinessPhoneLength &&
      !checkMobilePhoneLength &&
      !checkFaxLength
    ) {
      const data = getObjectDifferences(
        accountDetailsTestValues,
        accountDetailsFieldValues,
        accountDetailsFieldTitles
      )
      if (data !== undefined && data.length > 0) {
        const tempData: any[] = []
        data.map((element) => {
          if (
            element.field === 'Home Phone Number' ||
            element.field === 'Business Phone Number' ||
            element.field === 'Mobile Phone Number' ||
            element.field === 'Fax'
          ) {
            tempData.push({
              field: element?.field,
              oldValue:
                element?.oldValue?.length === 0
                  ? '--'
                  : formatPhoneNumber(element?.oldValue as string),
              newValue:
                element?.newValue?.length === 0
                  ? '--'
                  : formatPhoneNumber(element?.newValue as string),
            })
          } else {
            tempData.push({
              field: element?.field,
              oldValue: element?.oldValue ? element?.oldValue : '--',
              newValue: element?.newValue ? element?.newValue : '--',
            })
          }
        })

        accountDetailsValues?.setSubmissionTableData(tempData)
        accountDetailsValues?.setSubmissionPopUp(true)
        const temp: AccountDetailsUpdatedValues = {
          accountId: accountDetails?.accountId,
          accountNumber: `${accountDetails?.accountNumber}`,
          accountNickName: `${accountDetailsFieldValues.accountNickname}`,
          updateBy: authCtx?.account?.name,
          createdBy: authCtx?.account?.name,
          contactInfo: [
            {
              contactId: homePhone?.contact?.contactId ?? 0,
              contactTypeId: homePhone?.contact?.contactTypeId ?? 0,
              phoneNumber:
                homePhone?.contactNumberFormatted.replace(/[^\d]/g, '') ?? '',
            },
            {
              contactId: businessPhone?.contact?.contactId ?? 0,
              contactTypeId: businessPhone?.contact?.contactTypeId ?? 0,
              phoneNumber:
                businessPhone?.contactNumberFormatted.replace(/[^\d]/g, '') ??
                '',
            },
            {
              contactId: mobilePhone?.contact?.contactId ?? 0,
              contactTypeId: mobilePhone?.contact?.contactTypeId ?? 0,
              phoneNumber:
                mobilePhone?.contactNumberFormatted.replace(/[^\d]/g, '') ?? '',
            },
            {
              contactId: fax?.contact?.contactId ?? 0,
              contactTypeId: fax?.contact?.contactTypeId ?? 0,
              phoneNumber:
                fax?.contactNumberFormatted.replace(/[^\d]/g, '') ?? '',
            },
          ],
          emailInfo: [
            {
              emailId: personal?.emailId,
              emailTypeId: personal?.emailTypeId
                ? personal?.emailTypeId
                : personalEmailType?.emailTypeId,
              emailAddress: `${accountDetailsFieldValues.emailAddress}`,
            },
            {
              emailId: alternate?.emailId,
              emailTypeId: alternate?.emailTypeId
                ? alternate?.emailTypeId
                : alternateEmailType?.emailTypeId,
              emailAddress: `${accountDetailsFieldValues.emailAddress2}`,
            },
          ],
          notes: '',
        }

        accountDetailsValues?.setSubmissionPopUp(true)
        accountDetailsValues?.setAccountDetailsUpdateData(temp)
      }
    }
  }
  const AccountDetailsKeys = Object.values(AccountDetails)
  const AccountDetailsValues = Object.values(fieldValues)

  const edit = () => {
    approval?.setIsActiveAct(!approval?.isActiveAct)
    accountDetailsValues?.setAccountDetailsCollapse(true)
  }

  const collapse = () => {
    accountDetailsValues?.setAccountDetailsCollapse(
      !accountDetailsValues?.accountDetailsCollapse
    )
  }

  if (
    accountDetails?.accountNumber === undefined ||
    accountDetailsValues?.isLoading
  ) {
    return (
      <MasterWrapper>
        <TopRow>
          <HeaderRow>
            <TitleIconWrap>
              <IconWrapper>
                <SvgAccountDetails />
              </IconWrapper>
              <WDStyledSectionTitle>
                {messages.account_details_ui.title}
              </WDStyledSectionTitle>
            </TitleIconWrap>
          </HeaderRow>
        </TopRow>
        {
          <DividerWrapper>
            <Divider
              bgColor={COLORS.Background.Border}
              horizontal={true}
              thickness="1px"
            />
          </DividerWrapper>
        }
        <LoaderRow>
          <Loader />
        </LoaderRow>
      </MasterWrapper>
    )
  }

  const reloadApprovalTrail = () => {
    approval?.retrieveAuditTrail()
    setTimeout(() => {
      approval?.setIsLoading(true)
    }, 200)
    approval?.setIsLoading(false)
  }

  const reloadAuditTrail = () => {
    auditTrailValues?.retrieveAuditTrail()
    setTimeout(() => {
      auditTrailValues?.setIsLoading(true)
    }, 200)
    auditTrailValues?.setIsLoading(false)
  }

  const checkAccountAllowed = () => {
    if (accountDetailsValues?.accountDetails?.isAccountTypeAllowed === false && accountDetails?.system === 'P3') {
      accountDetailsValues?.setAccountAllowed(false)
      return false
    } else {
      accountDetailsValues?.setAccountAllowed(true)
      return true
    }
  }

  const reloadAccountDetails = () => {
    accountDetailsValues?.retrieveAccountDetails()
    accountDetailsValues?.setAccountDetailsCollapse(accountDetailsValues?.accountDetailsCollapse)
    approval?.setIsActiveAct(!approval?.isActiveAct)
  }

  return (
    <MasterWrapper
      borderShadow={accountDetailsValues?.accountDetailsCollapse ? true : false}
      id="account-details"
    >
      <TopRow>
        <HeaderRow onClick={() => collapse()}>
          <TitleIconWrap>
            <IconWrapper>
              {/* <Icon icon={Icons.Address} /> */}
              <SvgAccountDetails />
            </IconWrapper>
            <WDStyledSectionTitle>
              {messages.account_details_ui.title}
            </WDStyledSectionTitle>
          </TitleIconWrap>
        </HeaderRow>
        <TertiaryButtonWrapper>
          {!approval?.isActiveAct &&
            (approval?.admin || approval?.requester || approval?.requesterAndRestriction || !approval?.restrictionUser && authCtx?.userRoles?.length !== 0 && !approval?.approver) && (
              <>
                {!approval?.isActiveAd &&
                  !approval?.isActiveInv &&
                  !approval?.isActiveAl &&
                  /* !checkIsP3Account(accountDetails?.system as string) && */ (
                    <WDStyledEditButton onClick={() => {
                      const checkIsAccountAllowed =checkAccountAllowed()
                      checkIsAccountAllowed && edit()
                    }}>
                      <WDStyledEditButtonLabel>
                        {' '}
                        {messages.account_details_ui.buttons.edit}
                      </WDStyledEditButtonLabel>
                    </WDStyledEditButton>
                  )}
              </>
            )}
          <EditWrapper onClick={() => collapse()}>
            {accountDetailsValues?.accountDetailsCollapse && <SvgChevronUp />}
            {!accountDetailsValues?.accountDetailsCollapse && (
              <SvgChevronDown />
            )}
          </EditWrapper>
        </TertiaryButtonWrapper>
      </TopRow>
      {accountDetailsValues?.accountDetailsCollapse && (
        <DividerWrapper>
          <Divider
            bgColor={COLORS.Background.Border}
            horizontal={true}
            thickness="1px"
          />
        </DividerWrapper>
      )}

      {accountDetailsValues?.accountDetailsCollapse && (
        <React.Fragment>
          {/* The below section "Individual" contains all the fields necessary for an Individual Client */}
          <Individual>
            {!approval?.isActiveAct && (
              <React.Fragment>
                <Row>
                  <FlexItem>
                    <LabelWrap>
                      <WDReadModeTitle>{AccountDetailsKeys[0]}</WDReadModeTitle>
                    </LabelWrap>
                    <LabelWrap>
                      <WDReadModeValue>
                        {AccountDetailsValues[0] ? (
                          AccountDetailsValues[0]
                        ) : (
                          <span style={{ paddingLeft: '15px' }}>{'--'}</span>
                        )}
                      </WDReadModeValue>
                    </LabelWrap>
                  </FlexItem>
                  <FlexItem>
                    <LabelWrap>
                      <WDReadModeTitle>{AccountDetailsKeys[1]}</WDReadModeTitle>
                    </LabelWrap>
                    <LabelWrap>
                      <WDReadModeValue>
                        {AccountDetailsValues[1] ? (
                          AccountDetailsValues[1]
                        ) : (
                          <span style={{ paddingLeft: '15px' }}>{'--'}</span>
                        )}
                      </WDReadModeValue>
                    </LabelWrap>
                  </FlexItem>
                  <FlexItem>
                    <LabelWrap>
                      <WDReadModeTitle>{AccountDetailsKeys[2]}</WDReadModeTitle>
                    </LabelWrap>
                    <LabelWrap>
                      <WDReadModeValue>
                        {AccountDetailsValues[2] ? (
                          AccountDetailsValues[2]
                        ) : (
                          <span style={{ paddingLeft: '15px' }}>{'--'}</span>
                        )}
                      </WDReadModeValue>
                    </LabelWrap>
                  </FlexItem>
                  <FlexItem>
                    <LabelWrap>
                      <WDReadModeTitle>{AccountDetailsKeys[3]}</WDReadModeTitle>
                    </LabelWrap>
                    <LabelWrap>
                      <WDReadModeValue>
                        {AccountDetailsValues[3] ? (
                          AccountDetailsValues[3]
                        ) : (
                          <span style={{ paddingLeft: '15px' }}>{'--'}</span>
                        )}
                      </WDReadModeValue>
                    </LabelWrap>
                  </FlexItem>
                </Row>

                <Row>
                  <FlexItem>
                    <LabelWrap>
                      <WDReadModeTitle>{AccountDetailsKeys[4]}</WDReadModeTitle>
                    </LabelWrap>
                    <LabelWrap>
                      <WDReadModeValue>
                        {AccountDetailsValues[4] ? (
                          AccountDetailsValues[4]
                        ) : (
                          <span style={{ paddingLeft: '15px' }}>{'--'}</span>
                        )}
                      </WDReadModeValue>
                    </LabelWrap>
                  </FlexItem>
                  <FlexItem>
                    <LabelWrap>
                      <WDReadModeTitle>{AccountDetailsKeys[5]}</WDReadModeTitle>
                    </LabelWrap>
                    <LabelWrap>
                      <WDReadModeValue>
                        {AccountDetailsValues[5] ? (
                          AccountDetailsValues[5]
                        ) : (
                          <span style={{ paddingLeft: '15px' }}>{'--'}</span>
                        )}
                      </WDReadModeValue>
                    </LabelWrap>
                  </FlexItem>

                  <FlexItem>
                    <LabelWrap>
                      <WDReadModeTitle>{AccountDetailsKeys[6]}</WDReadModeTitle>
                    </LabelWrap>
                    <LabelWrap>
                      <WDReadModeValue>
                        {formatPhoneNumber(
                          homePhone?.contact?.phoneNumber as string
                        ) !== '' ? (
                          formatPhoneNumber(
                            homePhone?.contact?.phoneNumber as string
                          )
                        ) : (
                          <span style={{ paddingLeft: '15px' }}>{'--'}</span>
                        )}
                      </WDReadModeValue>
                    </LabelWrap>
                  </FlexItem>
                  <FlexItem>
                    <LabelWrap>
                      <WDReadModeTitle>{AccountDetailsKeys[7]}</WDReadModeTitle>
                    </LabelWrap>
                    <LabelWrap>
                      <WDReadModeValue>
                        {formatPhoneNumber(
                          businessPhone?.contact?.phoneNumber as string
                        ) !== '' ? (
                          formatPhoneNumber(
                            businessPhone?.contact?.phoneNumber as string
                          )
                        ) : (
                          <span style={{ paddingLeft: '15px' }}>{'--'}</span>
                        )}
                      </WDReadModeValue>
                    </LabelWrap>
                  </FlexItem>
                </Row>

                <Row>
                  <FlexItem>
                    <LabelWrap>
                      <WDReadModeTitle>{AccountDetailsKeys[8]}</WDReadModeTitle>
                    </LabelWrap>
                    <LabelWrap>
                      <WDReadModeValue>
                        {formatPhoneNumber(
                          mobilePhone?.contact?.phoneNumber as string
                        ) !== '' ? (
                          formatPhoneNumber(
                            mobilePhone?.contact?.phoneNumber as string
                          )
                        ) : (
                          <span style={{ paddingLeft: '15px' }}>{'--'}</span>
                        )}
                      </WDReadModeValue>
                    </LabelWrap>
                  </FlexItem>
                  <FlexItem>
                    <LabelWrap>
                      <WDReadModeTitle>{AccountDetailsKeys[9]}</WDReadModeTitle>
                    </LabelWrap>
                    <LabelWrap>
                      <WDReadModeValue>
                        {formatPhoneNumber(
                          fax?.contact?.phoneNumber as string
                        ) !== '' ? (
                          formatPhoneNumber(fax?.contact?.phoneNumber as string)
                        ) : (
                          <span style={{ paddingLeft: '15px' }}>{'--'}</span>
                        )}
                      </WDReadModeValue>
                    </LabelWrap>
                  </FlexItem>

                  <FlexItem>
                    <LabelWrap>
                      <WDReadModeTitle>
                        {AccountDetailsKeys[10]}
                      </WDReadModeTitle>
                    </LabelWrap>
                    <LabelWrap>
                      <WDReadModeValue>
                        {AccountDetailsValues[10] ? (
                          AccountDetailsValues[10]
                        ) : (
                          <span style={{ paddingLeft: '15px' }}>{'--'}</span>
                        )}
                      </WDReadModeValue>
                    </LabelWrap>
                  </FlexItem>
                  <FlexItem>
                    <LabelWrap>
                      <WDReadModeTitle>
                        {AccountDetailsKeys[11]}
                      </WDReadModeTitle>
                    </LabelWrap>
                    <LabelWrap>
                      <WDReadModeValue>
                        {AccountDetailsValues[11] ? (
                          AccountDetailsValues[11]
                        ) : (
                          <span style={{ paddingLeft: '15px' }}>{'--'}</span>
                        )}
                      </WDReadModeValue>
                    </LabelWrap>
                  </FlexItem>
                </Row>
              </React.Fragment>
            )}

            {approval?.isActiveAct && (
              <React.Fragment>
                <Row>
                  <FlexItem>
                    <InputField
                      label="Full Title of Account"
                      name=""
                      textboxPlaceholder=""
                      textboxDefaultValue={accountDetails?.fullTitleOfAccount}
                      disabled={true}
                      onChange={(e: string) =>
                        handleChange(e, 'FullTitleOfAccount')
                      }
                    />
                  </FlexItem>
                  <FlexItem>
                    <InputField
                      label="Account Nickname"
                      name=""
                      textboxPlaceholder=""
                      textboxDefaultValue={accountDetails?.accountNickName}
                      disabled={!approval?.isActiveAct}
                      onChange={(e: string) =>
                        handleChange(e, 'AccountNickname')
                      }
                    />
                  </FlexItem>
                  <FlexItem>
                    <InputField
                      label="Advisor Name"
                      name=""
                      textboxPlaceholder=""
                      textboxDefaultValue={accountDetails?.advisorName}
                      disabled={true}
                      onChange={(e: string) => handleChange(e, 'AdvisorName')}
                    />
                  </FlexItem>
                  <FlexItem>
                    <InputField
                      label="Rep Code"
                      name=""
                      textboxPlaceholder=""
                      textboxDefaultValue={accountDetails?.repCode}
                      disabled={true}
                      onChange={() => console.log()}
                    />
                  </FlexItem>
                </Row>

                <Row>
                  <FlexItem>
                    <InputField
                      label="Status"
                      name=""
                      textboxPlaceholder="Active"
                      textboxDefaultValue={accountDetails?.status}
                      disabled={true}
                      onChange={() => console.log()}
                    />
                  </FlexItem>
                  <FlexItem>
                    <InputField
                      label="Account Opening Date"
                      name=""
                      textboxPlaceholder=""
                      textboxDefaultValue={accountDetails?.accountOpeningDate?.slice(
                        0,
                        10
                      )}
                      disabled={true}
                      onChange={() => console.log()}
                    />
                  </FlexItem>
                  <FlexItem>
                    <ContentWrapper>
                      <InputField
                        label="Home Number"
                        name=""
                        textboxPlaceholder=""
                        textboxValue={homePhoneNumber}
                        textboxDefaultValue={formatPhoneNumber(
                          homePhone?.contact?.phoneNumber as string
                        )}
                        disabled={!approval?.isActiveAct}
                        onChange={(e: string) =>
                          handlePhoneNumber(e, 'HomePhone')
                        }
                        borderColor={homePhone?.isError ? '#BA0517' : '#CBD5E1'}
                        backgroundColor={
                          homePhone?.isError ? '#FEF1EE' : '#FFFFFF'
                        }
                      />
                      <AddWrapper>
                        <Button
                          bgColor={'transparent'}
                          color={'transparent'}
                          borderColor={'transparent'}
                          type={'button'}
                          onClick={() => removePhone(homePhone as ContactList)}
                        >
                          {' '}
                          {homePhone?.contact?.phoneNumber !== '' &&
                            countPhoneList(phoneList as ContactList[]) > 1 &&
                            Icons.DeleteIcon && (
                              <Image
                                image={Icons.DeleteIcon}
                                alt={'Icon'}
                                width={'12px'}
                                height={'13.33px'}
                              />
                            )}
                        </Button>
                      </AddWrapper>
                    </ContentWrapper>
                    {homePhone?.isError && (
                      <InlineErrorWrapper style={{ marginBottom: '8px' }}>
                        <SvgExclamation />
                        <Label
                          fontFamily={'SourceSansPro-Regular'}
                          fontSize={'14px'}
                          fontStyle={'normal'}
                          fontWeight={400}
                          lineHeight={'16px'}
                          color={'#BA0517'}
                        >
                          {homePhone.errorMessage}
                        </Label>
                      </InlineErrorWrapper>
                    )}
                  </FlexItem>
                  <FlexItem>
                    <ContentWrapper>
                      <InputField
                        label="Business Number"
                        name=""
                        textboxPlaceholder=""
                        textboxValue={businessPhoneNumber}
                        textboxDefaultValue={formatPhoneNumber(
                          businessPhone?.contact?.phoneNumber as string
                        )}
                        disabled={!approval?.isActiveAct}
                        onChange={(e: string) =>
                          handlePhoneNumber(e, 'BusinessPhone')
                        }
                        borderColor={
                          businessPhone?.isError ? '#BA0517' : '#CBD5E1'
                        }
                        backgroundColor={
                          businessPhone?.isError ? '#FEF1EE' : '#FFFFFF'
                        }
                      />
                      <AddWrapper>
                        <Button
                          bgColor={'transparent'}
                          color={'transparent'}
                          borderColor={'transparent'}
                          type={'button'}
                          onClick={() =>
                            removePhone(businessPhone as ContactList)
                          }
                        >
                          {' '}
                          {businessPhone?.contact?.phoneNumber !== '' &&
                            countPhoneList(phoneList as ContactList[]) > 1 &&
                            Icons.DeleteIcon && (
                              <Image
                                image={Icons.DeleteIcon}
                                alt={'Icon'}
                                width={'12px'}
                                height={'13.33px'}
                              />
                            )}
                        </Button>
                      </AddWrapper>
                    </ContentWrapper>
                    {businessPhone?.isError && (
                      <InlineErrorWrapper style={{ marginBottom: '8px' }}>
                        <SvgExclamation />
                        <Label
                          fontFamily={'SourceSansPro-Regular'}
                          fontSize={'14px'}
                          fontStyle={'normal'}
                          fontWeight={400}
                          lineHeight={'16px'}
                          color={'#BA0517'}
                        >
                          {businessPhone.errorMessage}
                        </Label>
                      </InlineErrorWrapper>
                    )}
                  </FlexItem>
                </Row>

                <Row>
                  <FlexItem>
                    <ContentWrapper>
                      <InputField
                        label="Mobile Number"
                        name=""
                        textboxPlaceholder=""
                        textboxValue={mobilePhoneNumber}
                        textboxDefaultValue={formatPhoneNumber(
                          mobilePhone?.contact?.phoneNumber as string
                        )}
                        disabled={!approval?.isActiveAct}
                        onChange={(e: string) =>
                          handlePhoneNumber(e, 'MobilePhone')
                        }
                        borderColor={
                          mobilePhone?.isError ? '#BA0517' : '#CBD5E1'
                        }
                        backgroundColor={
                          mobilePhone?.isError ? '#FEF1EE' : '#FFFFFF'
                        }
                      />
                      <AddWrapper>
                        <Button
                          bgColor={'transparent'}
                          color={'transparent'}
                          borderColor={'transparent'}
                          type={'button'}
                          onClick={() =>
                            removePhone(mobilePhone as ContactList)
                          }
                        >
                          {' '}
                          {mobilePhone?.contact?.phoneNumber !== '' &&
                            countPhoneList(phoneList as ContactList[]) > 1 &&
                            Icons.DeleteIcon && (
                              <Image
                                image={Icons.DeleteIcon}
                                alt={'Icon'}
                                width={'12px'}
                                height={'13.33px'}
                              />
                            )}
                        </Button>
                      </AddWrapper>
                    </ContentWrapper>
                    {mobilePhone?.isError && (
                      <InlineErrorWrapper style={{ marginBottom: '8px' }}>
                        <SvgExclamation />
                        <Label
                          fontFamily={'SourceSansPro-Regular'}
                          fontSize={'14px'}
                          fontStyle={'normal'}
                          fontWeight={400}
                          lineHeight={'16px'}
                          color={'#BA0517'}
                        >
                          {mobilePhone.errorMessage}
                        </Label>
                      </InlineErrorWrapper>
                    )}
                  </FlexItem>
                  <FlexItem>
                    <ContentWrapper>
                      <InputField
                        label="Fax"
                        name=""
                        textboxPlaceholder=""
                        textboxValue={faxNumber}
                        textboxDefaultValue={formatPhoneNumber(
                          fax?.contact?.phoneNumber as string
                        )}
                        disabled={!approval?.isActiveAct}
                        onChange={(e: string) => handlePhoneNumber(e, 'Fax')}
                        borderColor={fax?.isError ? '#BA0517' : '#CBD5E1'}
                        backgroundColor={fax?.isError ? '#FEF1EE' : '#FFFFFF'}
                      />
                      <AddWrapper>
                        <Button
                          bgColor={'transparent'}
                          color={'transparent'}
                          borderColor={'transparent'}
                          type={'button'}
                          onClick={() => removePhone(fax as ContactList)}
                        >
                          {' '}
                          {fax?.contact?.phoneNumber !== '' &&
                            countPhoneList(phoneList as ContactList[]) > 1 &&
                            Icons.DeleteIcon && (
                              <Image
                                image={Icons.DeleteIcon}
                                alt={'Icon'}
                                width={'12px'}
                                height={'13.33px'}
                              />
                            )}
                        </Button>
                      </AddWrapper>
                    </ContentWrapper>
                    {fax?.isError && (
                      <InlineErrorWrapper style={{ marginBottom: '8px' }}>
                        <SvgExclamation />
                        <Label
                          fontFamily={'SourceSansPro-Regular'}
                          fontSize={'14px'}
                          fontStyle={'normal'}
                          fontWeight={400}
                          lineHeight={'16px'}
                          color={'#BA0517'}
                        >
                          {fax.errorMessage}
                        </Label>
                      </InlineErrorWrapper>
                    )}
                  </FlexItem>
                  <FlexItem>
                    <InputField
                      label="Email Address*"
                      name=""
                      textboxPlaceholder=""
                      textboxDefaultValue={fieldValues.EmailAddress}
                      disabled={!approval?.isActiveAct}
                      onChange={(e: string) =>
                        handleEmailAddress(
                          e,
                          messages.account_details_ui.field_names.personal
                        )
                      }
                      borderColor={personal?.isError ? '#BA0517' : '#CBD5E1'}
                      backgroundColor={
                        personal?.isError ? '#FEF1EE' : '#FFFFFF'
                      }
                    />
                    {personal?.isError && (
                      <InlineErrorWrapper style={{ marginBottom: '8px' }}>
                        <SvgExclamation />
                        <Label
                          fontFamily={'SourceSansPro-Regular'}
                          fontSize={'14px'}
                          fontStyle={'normal'}
                          fontWeight={400}
                          lineHeight={'16px'}
                          color={'#BA0517'}
                        >
                          {personal.errorMessage}
                        </Label>
                      </InlineErrorWrapper>
                    )}
                  </FlexItem>
                  <FlexItem>
                    <ContentWrapper>
                      <InputField
                        label="Email Address 2"
                        name=""
                        textboxPlaceholder=""
                        textboxDefaultValue={fieldValues.EmailAddress2}
                        disabled={!approval?.isActiveAct}
                        onChange={(e: string) =>
                          handleEmailAddress(
                            e,
                            messages.account_details_ui.field_names.alternate
                          )
                        }
                        borderColor={alternate?.isError ? '#BA0517' : '#CBD5E1'}
                        backgroundColor={
                          alternate?.isError ? '#FEF1EE' : '#FFFFFF'
                        }
                      />
                      <AddWrapper>
                        <Button
                          bgColor={'transparent'}
                          color={'transparent'}
                          borderColor={'transparent'}
                          type={'button'}
                          onClick={() => removeEmail(alternate as EmailInfo)}
                        >
                          {' '}
                          {alternate?.emailAddress !== '' &&
                            Icons.DeleteIcon && (
                              <Image
                                image={Icons.DeleteIcon}
                                alt={'Icon'}
                                width={'12px'}
                                height={'13.33px'}
                              />
                            )}
                        </Button>
                      </AddWrapper>
                    </ContentWrapper>
                    {alternate?.isError && (
                      <InlineErrorWrapper style={{ marginBottom: '8px' }}>
                        <SvgExclamation />
                        <Label
                          fontFamily={'SourceSansPro-Regular'}
                          fontSize={'14px'}
                          fontStyle={'normal'}
                          fontWeight={400}
                          lineHeight={'16px'}
                          color={'#BA0517'}
                        >
                          {alternate.errorMessage}
                        </Label>
                      </InlineErrorWrapper>
                    )}
                  </FlexItem>
                </Row>
                <DividerWrapper2>
                  <Divider
                    bgColor={COLORS.Background.Border}
                    horizontal={true}
                    thickness="1px"
                  />
                </DividerWrapper2>

                <ButtonRow>
                  <SecondaryButtonWrapper>
                    <WDStyledCancelButton onClick={() => cancelFunction()}>
                      <WDStyledCancelButtonLabel>
                        {messages.account_details_ui.buttons.cancel}
                      </WDStyledCancelButtonLabel>
                    </WDStyledCancelButton>
                  </SecondaryButtonWrapper>
                  <PrimaryButtonWrapper>
                    <WDStyledSaveButton
                      onClick={() => {
                        save()
                      }}
                    >
                      <WDStyledCancelButtonLabel>
                        {messages.account_details_ui.buttons.save}
                      </WDStyledCancelButtonLabel>
                    </WDStyledSaveButton>
                  </PrimaryButtonWrapper>
                </ButtonRow>
              </React.Fragment>
            )}
          </Individual>
        </React.Fragment>
      )}
      {accountDetailsValues?.showConfirmDialog &&
        removeItem === messages.account_details_ui.field_names.email && (
          <ConfirmationDailog
            id={removedEmail?.emailId}
            type={removedEmail?.emailType}
            accountId={accountDetails.accountId}
            emailTypeId={removedEmail?.emailTypeId}
            isEmail={true}
            onClick={(value: boolean) => handleRemove(value, false)}
          />
        )}
      {accountDetailsValues?.showConfirmDialog &&
        removeItem === messages.account_details_ui.field_names.phone && (
          <ConfirmationDailog
            id={removedContact?.contact?.contactId}
            type={removedContact?.contactType}
            isPhone={true}
            onClick={(value: boolean) => handleRemove(value, true)}
          />
        )}

      {success === undefined || success === 100 ? null : success === 200 ? (
        <ErrorModal
          success={200}
          onClick={() => {
            accountDetailsValues?.setErrorMsg(100)
            reloadAccountDetails()
            reloadAuditTrail()
            reloadApprovalTrail()
          }}
          successMsg={
            messages.account_details_ui.success_message.success_message
          }
        />
      ) : (
        <ErrorModal
          onClick={() => {
            accountDetailsValues?.setErrorMsg(100)
          }}
          success={500}
        />
      )}
      {removePhoneResponseCode === undefined ||
        removePhoneResponseCode === 100 ? null : removePhoneResponseCode ===
          200 ? (
        <ErrorModal
          success={200}
          onClick={() => {
            accountDetailsValues?.setRemovePhoneResponseCode(100)
            reloadAccountDetails()
            reloadAuditTrail()
          }}
          successMsg={messages.account_details_ui.success_message.success_message_remove_phone(
            removedContact?.contactType as string
          )}
        />
      ) : (
        <ErrorModal
          onClick={() => {
            accountDetailsValues?.setRemovePhoneResponseCode(100)
          }}
          success={500}
        />
      )}
      {removeEmailResponseCode === undefined ||
        removeEmailResponseCode === 100 ? null : removeEmailResponseCode ===
          200 ? (
        <ErrorModal
          success={200}
          onClick={() => {
            accountDetailsValues?.setRemoveEmailResponseCode(100)
            reloadAccountDetails()
            reloadAuditTrail()
          }}
          successMsg={messages.account_details_ui.success_message.success_message_remove_email(
            messages.account_details_ui.field_names.alternate
          )}
        />
      ) : (
        <ErrorModal
          onClick={() => {
            accountDetailsValues?.setRemoveEmailResponseCode(100)
          }}
          success={500}
        />
      )}
      {accountDetailsValues?.submissionPopUp && (
        <AccountDetailsSubmissionPage />
      )}
    </MasterWrapper>
  )
}
